import React from "react"
import { LocaleContext } from "../components/layout"
import PolicyDe from "../components/privacyPolicyDe"
import PolicyEn from "../components/privacyPolicyEn"

export default function Datenschutz() {
  const { locale } = React.useContext(LocaleContext)
  return (
    <div className="mx-auto my-8 prose prose-stone prose-h1:text-als-red">
      {locale === 'de' ?
        <PolicyDe 
          hoster="Timme Hosting GmbH & Co. KG, Ovelgönner Weg 43, 21335 Lüneburg, Deutschland"
          contact="ALS Bootsverleih®, Herr Frank Graner, Lindenstr. 27, 12555 Berlin"
          phone="+4930 – 346 253 03"
          emailLink="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x69;&#x6e;&#x66;&#x6f;&#x40;&#x61;&#x6c;&#x73;&#x2d;&#x62;&#x6f;&#x6f;&#x74;&#x73;&#x76;&#x65;&#x72;&#x6c;&#x65;&#x69;&#x68;&#x2e;&#x64;&#x65;"
          email="&#x69;&#x6e;&#x66;&#x6f;&#x40;&#x61;&#x6c;&#x73;&#x2d;&#x62;&#x6f;&#x6f;&#x74;&#x73;&#x76;&#x65;&#x72;&#x6c;&#x65;&#x69;&#x68;&#x2e;&#x64;&#x65;"
        /> :
        <PolicyEn
          hoster="Timme Hosting GmbH & Co. KG, Ovelgönner Weg 43, 21335 Lüneburg, Germany"
          contact="ALS Bootsverleih®, Herr Frank Graner, Lindenstr. 27, 12555 Berlin, Germany"
          phone="+4930 – 346 253 03"
          emailLink="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x69;&#x6e;&#x66;&#x6f;&#x40;&#x61;&#x6c;&#x73;&#x2d;&#x62;&#x6f;&#x6f;&#x74;&#x73;&#x76;&#x65;&#x72;&#x6c;&#x65;&#x69;&#x68;&#x2e;&#x64;&#x65;"
          email="&#x69;&#x6e;&#x66;&#x6f;&#x40;&#x61;&#x6c;&#x73;&#x2d;&#x62;&#x6f;&#x6f;&#x74;&#x73;&#x76;&#x65;&#x72;&#x6c;&#x65;&#x69;&#x68;&#x2e;&#x64;&#x65;"
        />
      }
    </div>
  )
}